import React from 'react';
import { NavLink } from 'react-router-dom';
import { FaLandmark } from "react-icons/fa";
import { FaMountain } from "react-icons/fa";
import { FaRegPlayCircle } from "react-icons/fa";
import { FaPhoneAlt } from "react-icons/fa";
import { GoMarkGithub } from "react-icons/go";
import { FaLinkedin } from "react-icons/fa";


const Navigation = () => {
    return (
        <div className='sidebar'>
            <div className='id'>
                <div className='idContent'>
                    {/* <img style={{display: 'none'}} src='./media/rod.png' alt='photo_rod'/> */}
                    <h3 style={{marginBottom: '60px'}}>Rodolphe MINGO</h3>

                </div>
            </div>

            <div className='navigation'>
                <ul>
                    <li>
                    <NavLink end to="/" >
                         <FaLandmark className='FaHome'/>
                        <span>Accueil</span>
                    </NavLink>
                    </li>
                    <li>
                    <NavLink end to="/competences" >
                         <FaMountain className='FaHome'/>
                        <span>Compétences</span>
                    </NavLink>
                    </li>
                    <li>
                    <NavLink end to="/portfolio" >
                         <FaRegPlayCircle className='FaHome'/>
                        <span>Portfolio</span>
                    </NavLink>
                    </li>
                    <li>
                    <NavLink end to="/contact" >
                         <FaPhoneAlt className='FaHome'/>
                        <span>Contact</span>
                    </NavLink>
                    </li>
                </ul>
            </div>

            <div className='socialNetwork'>
                <ul>
                    <li>
                        <a href='https://github.com/Rod97139' target='_blank' rel="noreferrer"><GoMarkGithub/></a>
                    
                    </li>
                    <li> 
                          <a href='https://www.linkedin.com/in/rod-%F0%9F%88%BA-mingo-8b3699251/' target='_blank' rel="noreferrer"><FaLinkedin/></a>
                    </li>
                </ul>

                <div className='signature'>
                    <p>RM - 2024</p>
                </div>
            </div>
            
        </div>
    );
};

export default Navigation;