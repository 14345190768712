import React from "react";

const Experience = () =>{
    return (
        <div className="experience">
              <h3>  expérience - Diplômes</h3>
              
              <div className="exp-1">
                <h4 > Développeur Web, Gestionnaire Base de Données </h4>
                
                <h5>- 2023, Association Building Seniors, L'Isle-Adam (95)</h5>
                <p>Création d’un système de Messagerie
                instantanée et de gestion de contacts
                (Symfony, Doctrine, Mercure, JS, Ajax, SQL) </p>
                <p>Prise en charge d’appels visio (webRTC, JS)</p>
                <p>Github - CD</p>
                
              </div>
              <div className="exp-2">
                <h4 > TP (niveau 6) Développeur Concepteur Logiciel </h4>
                
                <h5>- 2024, OpenClassrooms, Paris (75)</h5>
                <p> Projets front-end Javascript-React</p>
                <p>TravisCI - Jest - React testing library</p>
              </div>
              
              <div className="exp-3">
                <h4 > TP (niveau 5) Développeur Web et Web Mobile </h4>
                
                <h5>- 2023, Afpa, La Sentinelle (59)</h5>
                <p> Projet e-commerce (PHP natif, Stripe, Paypal)</p>
                <p>Projet Réservation de Gîtes (Symfony, JS, Ajax, Mercure, SQL, Github)</p>
              </div>
              
              <div className="exp-4">
                <a href='https://resultat-bac.linternaute.com/academie-guadeloupe/2011/m?page=4' target='_blank' rel="noreferrer" ><h4 className="titreM"> BAC STI Génie électronique </h4></a>
                
                <h5>- 2011, Les Abymes (971)</h5>
                <p>Projet CAO (Solidworks)</p>
                <p> Électronique/Mécanique
                </p>
              </div>
{/*                 
                <h4 > Actuellement en formation DWWM </h4>
                
                <h5>- du 20 juin 2022 au 19 avril 2023</h5> */}
                
                

        </div>
    );

    };

    export default Experience;