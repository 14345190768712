import React from 'react';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import Home from './pages/Home';
import Knowledge from './pages/Knowledge';
import Contact from './pages/Contact';
import PortFolio from './pages/PortFolio';
import NotFound from './pages/NotFound';

const App = () => {
  return (    
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home/>}/>   {/* exact */}
          <Route path="/competences" element={<Knowledge/>}/>
          <Route path="/portfolio" element={<PortFolio/>}/>
          <Route path="/contact" element={<Contact/>}/>
          <Route path= "*" element={<NotFound/>}/>
        </Routes>
      </BrowserRouter>
      );
};

export default App;