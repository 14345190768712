import React from 'react';

import { FaBasketballBall, FaBook, FaMusic, FaTrain} from "react-icons/fa";


const Hobbies = () => {
    return (
        <div className='hobbies'>
          <h3>
              Hobbies</h3>
              
            
                <ul>
                    <li><FaBasketballBall/> Basket</li>
                    <li><FaBook/> Lecture</li>
                <li><FaMusic/> Musique</li>
                <li><FaTrain/> Voyages</li>
                    
                </ul>

            
        

        
        </div>
    );
};

export default Hobbies;