import React, { Component } from 'react';
import { TbBrandTypescript } from "react-icons/tb";
import { SiJquery, SiStimulus, SiWebrtc, SiWebpack, SiVitest, SiFastify, SiBootstrap } from "react-icons/si";
import { DiDoctrine } from "react-icons/di";
import { FiExternalLink } from "react-icons/fi";
import { RiCodeSSlashFill } from "react-icons/ri";

class Project extends Component {

    state ={
        showInfo: false
    }

    handleInfo = () => {
        window.scrollTo({ top: 50, behavior: 'smooth' })
        
        this.setState({
            showInfo: !this.state.showInfo
        })
    }

     showIcon = (icon) => {

        if (icon ===  'fab fa-typescript') 
        {
            return <TbBrandTypescript className='doctrine'/>

        }else if (icon ===  'stimulus'){
                return <SiStimulus className='typescript'/>
        }else if (icon ===  'webRTC'){
                return <SiWebrtc className='typescript'/>
        }else if (icon ===  'jQuery'){
                return <SiJquery className='typescript'/>
        }else if (icon ===  'mariadb'){
                return <SiWebpack className='doctrine'/>
        }else if (icon ===  'doctrine'){
                return <DiDoctrine className='doctrine'/>
        }else if (icon ===  'vitest'){
                return <SiVitest className='doctrine'/>
        }else if (icon ===  'fastify'){
                return <SiFastify className='doctrine'/>
        }else if (icon ===  'bootstrap'){
                return <SiBootstrap className='doctrine'/>
        }else{
            return  <i className={icon} key={icon}></i> 
        }
        // if (icon ===  'fab fa-typescript') return <SiTypescript className='typescript'/>
        
    }

    // handleExit = () => {
    //     this.setState({

    //     })
    // }

    render() {

       let {name, languagesIcons, source, url, info, picture} = this.props.item;
       
       
       return (
            <div className='project'>
                <div className='icons'>
                    {languagesIcons.map(icon=> this.showIcon(icon))}
                </div>
                <h3>{name}</h3>
                <img src={picture} alt='' onClick={this.handleInfo}/>
                <span className='infos' onClick={this.handleInfo}>
                        <i className='fas fa-plus-circle'></i>
                </span>
                        
                {
                this.state.showInfo && (
                    <div className='showInfos'>
                        <div className='infosContent'>
                            <div className='head'>
                                <h2>{name}</h2>
                                <div className='sourceCode'>
                                <a href={source} id={source ? 'showSource' : 'hide'} rel='noopener noreferrer' className='button' style={{marginRight: '10px'}} target='_blank'> <RiCodeSSlashFill className='sourceCode'/>
                                
                            </a>
                                <a href={url} id={url ? 'showSource' : 'hide'} rel='noopener noreferrer' className='button' target='_blank'><FiExternalLink  className='sourceCode'/>
                                
                            </a>
                            </div>
                            </div>
                            
                            <p className='text'> {info}</p>

                            <div className='button return'  onClick={this.handleInfo}>Retour</div>
                        </div>
                    </div>
                )
                } 
            </div>
        );
    }
}

export default Project;