import React from 'react';
import { FaLandmark } from "react-icons/fa";
import { NavLink } from 'react-router-dom';
const NotFound = () => {
    return (
        <div className='notFound'>
            <div className='notFoundContent'>
                <h3>404</h3>
                <NavLink end to='/'>
                <FaLandmark className='FaHome'/>
                <span>Accueil</span>
                </NavLink>
            </div>
        </div>
    );
};

export default NotFound;