import React from 'react';

import { FaCheckSquare } from "react-icons/fa";
const Otherskills = () => {
    return (
        <div className='otherSkills'>
            <h3> Autres Compétences</h3>
            <div className='list'>
                <ul>
                    <li className='titreM '><FaCheckSquare/>Anglais niveau B2 </li>
                   
                  
                </ul>

            </div>
        

        </div>
    );
};

export default Otherskills