import React from 'react';
import Navigation from '../components/Navigation';
import {CopyToClipboard} from 'react-copy-to-clipboard'

const Contact = () => {
    return (
        <div className='contact'>
            <Navigation/>
            <div className='contactContent'>
            <div className='header'></div>
                <div className='contactBox'>
                <h1>Contactez moi</h1>
                <ul>
                    <li>
                        <i className='fas fa-map-marker-alt'></i>
                        <span>Rennes</span>
                    </li>
                    <li>
                        <i className='fas fa-mobile-alt'></i>
                        <CopyToClipboard text='0768050199'>
                            <span className='clickInput' onClick={() => {
                            alert ('Téléphone copié !'); }}> 07 68 05 01 99

                            </span>
                        </CopyToClipboard>
                    </li>
                    <li>
                        <i className='fas fa-envelope mailRod'></i>
                        <CopyToClipboard text='rodolphe.mingo@yahoo.com'>
                            <span className='clickInput' onClick={() => {
                            alert ('E-mail copié !'); }}> rodolphe.mingo@yahoo.com

                            </span>
                        </CopyToClipboard>
                    </li>
                </ul>
                </div>
                <div className='socialNetwork'>
                    <ul>
                        <a href="https://github.com/Rod97139" target='_blank' rel="noopener noreferrer">
                            <h4>GitHub</h4>
                            <i className='fab fa-github'></i>
                        </a>
                        <a href="https://www.linkedin.com/in/rod-%F0%9F%88%BA-mingo-8b3699251/" target='_blank' rel="noopener noreferrer">
                             <h4>LinkedIn</h4>
                            <i className='fab fa-linkedin'></i>
                        </a>
                    </ul>

                </div>
            </div>
        </div>
    );
};

export default Contact;