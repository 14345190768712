import React, { Component } from 'react';
import ProgressBar from './ProgressBar';

class Languages extends Component {
    state = {
        languages : [
            {id:1, value: 'HTML', xp: 1.2},
            {id:2, value: 'CSS', xp: 1.2},
            {id:3, value: 'JavaScript', xp:1.2},
            {id:4, value: 'PHP', xp:0.9}
        ],
        frameworks : [
            {id:1, value: 'React', xp: 1},
            {id:2, value: 'Next', xp: 0.7},
            {id:3, value: 'Symfony', xp: 0.9}
                    ],
        librairies : [
            {id:1, value: 'Axios', xp:1},
        ]
    }

    render() {
        let {languages, frameworks, librairies} = this.state;

        return (
            <div className='languagesFrLi'>
                <ProgressBar
                 languages={languages}
                 className='languagesDisplay'
                 title='langages'
                />
                <ProgressBar
                languages={frameworks}
                title='frameworks'
                className='frameworksDisplay' 
                />
                <ProgressBar
                    languages={librairies}
                    title='librairies'
                    className='librairiesDisplay'
                    />
                
                
            </div>
        );
    }
}

export default Languages;