export const portfolioData = [
  {
    id: 1,
    name: 'Les Petits Plats',
    languages: ['Front-end'],
    languagesIcons: ['fab fa-html5','fab fa-js', 'fab fa-css3-alt'],
    source: 'https://github.com/Rod97139/les-petits-plats',
    url: 'https://rod97139.github.io/les-petits-plats/',
    info: 'Tailwind CSS et Javascript natif',
    picture: './media/petits-plats.png'
  },
  {
    id: 2,
    name: 'Argent Bank',
    languages: ['Front-end'],
    languagesIcons: ['fab fa-html5','fab fa-js', 'fab fa-css3-alt'],
    source: 'https://github.com/Rod97139/argent-bank-frontend',
    url: 'http://react.rodolphe.mingo.qc.lu/argentbank/',
    info: 'Authentification avec formulaire et gestion de compte - Redux, Node et mongoDB',
    picture: './media/argentBank.png'
  },
  {
    id: 3,
    name: 'Oh My Food',
    languages: ['Front-end'],
    languagesIcons: ['fab fa-html5', 'fab fa-sass'],
    source: 'https://github.com/Rod97139/oh-my-food',
    url: 'https://rod97139.github.io/oh-my-food/',
    info: 'Animation Sass',
    picture: './media/oh-my-food.png'
  },
  {
    id: 4,
    name: 'GameOne',
    languages: ['Front-end'],
    languagesIcons: ['fab fa-html5','fab fa-js', 'fab fa-css3-alt'],
    source: 'https://github.com/Rod97139/GameOn-website-FR',
    url: 'https://rod97139.github.io/GameOn-website-FR/',
    info: 'Landing page (Regex - formulaire)',
    picture: './media/form.png'
  },
  {
    id: 5,
    name: 'FishEye',
    languages: ['Front-end'],
    languagesIcons: ['fab fa-js', 'fab fa-sass'],
    source: 'https://github.com/Rod97139/Fisheye',
    url: 'https://rod97139.github.io/Fisheye/photographer.html?id=243&media=5&sortBy=likes',
    info: 'Spa Framework en Javascript natif',
    picture: './media/fisheye.png'
  },
  {
    id: 6,
    name: 'Kasa',
    languages: ['Front-end'],
    languagesIcons: ['fab fa-react', 'fab fa-sass'],
    source: 'https://github.com/Rod97139/kasa',
    url: 'https://react.rodolphe.mingo.qc.lu/kasa/',
    info: '',
    picture: './media/kasa.png'
  },
  {
    id: 7,
    name: 'Sport See',
    languages: ['Front-end'],
    languagesIcons: ['fab fa-react', 'fab fa-sass'],
    source: 'https://github.com/Rod97139/SportSee',
    url: 'https://react.rodolphe.mingo.qc.lu/sportsee/user/18',
    info: '',
    picture: './media/sportSee.png'
  },
  {
    id: 8,
    name: 'HRnet',
    languages: ['Front-end'],
    languagesIcons: ['fab fa-react', 'fab fa-sass'],
    source: 'https://github.com/Rod97139/HRnet-react',
    url: 'https://react.rodolphe.mingo.qc.lu/HRnet-react',
    info: 'Conversion jQuery vers React, lien plugin react: https://www.npmjs.com/package/react-data-table-by-rod',
    picture: './media/hr.png'
  },
  {
    id: 9,
    name: 'Retro Snake',
    languages: ['Front-end'],
    languagesIcons: ['fab fa-html5','fab fa-css3-alt', 'fab fa-js','jQuery'],
    source: 'https://github.com/Rod97139/retro-snake',
    url: 'https://rod97139.github.io/retro-snake/',
    info: 'retro snake avec WebAssembly et C++',
    picture: './media/projetMastermind.png'
  },
  {
    id: 10,
    name: 'Puissance 4',
    languages: ['Back-end'],
    languagesIcons: ['fab fa-typescript', 'fab fa-node-js', 'fastify', 'fab fa-react', 'fab fa-css3-alt', 'vitest'],
    source: 'https://github.com/Rod97139/puissance4',
    url: '',
    info: 'Démarrer la partie lorsque les deux joueurs ont choisi leur couleur',
    picture: './media/projetConnect4.png'
  },
  {
    id: 11,
    name: 'webRTC',
    languages: ['Back-end'],
    languagesIcons: ['fab fa-html5','fab fa-js', 'bootstrap', 'webRTC'],
    source: 'https://github.com/Rod97139/webRTC',
    url: 'https://rodolphe.mingo.qc.lu/projets/webRTC/index.html',
    info: 'Système d\'appels visio avec simple-peer',
    picture: './media/projet-webRTC.png'
  },
  {
    id: 12,
    name: 'Progika',
    languages: ['Back-end'],
    languagesIcons: ['fab fa-js', 'fab fa-php', 'fab fa-symfony', 'bootstrap', 'fab fa-sass', 'stimulus', 'mariadb', 'doctrine' ],
    source: 'https://github.com/Rod97139/progika',
    url: '',
    info: 'Plate-forme d\'annonces (Symfony 6) ',
    picture: './media/projetProgica.png'
  },
  {
    id: 13,
    name: 'Best Wines',
    languages: ['Back-end'],
    languagesIcons: ['fab fa-php','fab fa-css3-alt', 'fab fa-js','bootstrap'],
    source: 'https://github.com/Rod97139/best-wines-v1.1',
    url: '',
    info: 'Plateforme E-commerce avec payement paypal et stripe',
    picture: './media/paypal.png'
  },

]